/// in MyComponent.jsx

import { useWindowScrollPositions } from "./useWindowScrollPositions"
import '../App.css';


export const Plx2 = () => {
    const {scrollY } = useWindowScrollPositions()
    const iframe= '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1463813932%3Fsecret_token%3Ds-2QeYfDpgOHU&color=%23040404&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/real-friendz" title="Real Friendz" target="_blank" style="color: #cccccc; text-decoration: none;">Real Friendz</a> · <a href="https://soundcloud.com/real-friendz/sets/wara-influenza-final-mixes/s-2QeYfDpgOHU" title="WARA - INFLUENZA - FINAL MIXES" target="_blank" style="color: #cccccc; text-decoration: none;">WARA - INFLUENZA - FINAL MIXES</a></div>'

    // var number = Math.min(Math.max(parseInt(scrolly), 1), 60);
    var limit = Math.max( document.body.scrollHeight, document.body.offsetHeight, 
        document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
        //" + parseInt((scrollY/limit)*66)+ "
        
    
   return <div className="bg" style={{position:"relative", }} > 
   
   <div style={{ 
        height:'100vh',
        // width: '100vw',
        position: '-webkit-sticky',
        position: 'sticky',
       // border:'1px solid red',
        top: 0,
        // top: 0, margin: 0, //height: "100vh", 
        // marginTop: "-100vh",
        backgroundAttachment: 'scroll',
        backgroundImage: "url(/plx/" + parseInt((scrollY/limit)*66)+ ".gif)", 
        backgroundRepeat: "no-repeat", 
        zIndex:10,
        backgroundSize: "cover", 
        backgroundPosition:"center center",
        
        }}>
          
    </div>

        <div style={{maxWidth: '390px', margin:"auto",overflowY: "scroll",zIndex:15,  position: '-webkit-sticky',
        position: 'absolute', left: 0, right: 0, top: 0, fontFamily: "menlo", fontSize: '20px', margin: 'auto',width: "100vw",mixBlendMode: "difference", color: "rgb(255,255,242)"}} >
            
<div className="imgContainer">
    <img className="colorimage" src="/img/bodypix/influenza_logo_inv.jpg" style={{width: "100%", margin:"auto", marginTop: "15vh"}}/>
</div>
<p className="top">
4 YEARS IN THE MAKING WE OFFICIALLY <br></br>
STARTED THIS JOURNEY IN SUMMER 2018<br></br>
IN THE PROCESS I NOT ONLY BECAME<br></br>
A BETTER HUMAN BEING THRU UNDERSTANDING<br></br>
MYSELF AND ACCEPTING MY FLAWS AS A MAN BUT I ALSO<br></br>
DEVELOPED INTO THE ARTIST THATS <br></br>
NOW BEING PRESENTED TO THE WORLD TODAY<br></br>
</p>
<div className="imgContainer">
    <img className="colorimage" src="/img/bodypix/1.png" style={{width:"100%", margin:"auto"}}/>
</div>
<p>
WITH THIS BODY OF WORK MY MAIN FOCUS<br></br>
WAS TO FIND A WAY TO DISPLAY ALL OF MY STYLES<br></br>
SONICALLY AND STILL TELL MY STORY CLEARLY<br></br>
WITHOUT COMPROMISE.<br></br><br></br> 
</p>

<div className="imgContainer">
    <img className="colorimage" src="/img/bodypix/2.png" style={{width:"100%", margin:"auto"}}/>
</div>
<p>
‘INFLUENZA’ IS NOT YOUR<br></br>
AVERAGE ALBUM THERES SO MANY DIFFERENT MOODS<br></br>
WHICH ARE A DIRECT REFLECTION OF MY PERSONALITY<br></br>
AND INFLUENCES THAT INSPIRED ME THRUOUT MY YEARS<br></br>
OF BEING A MUSICIAN BUT THE SOUNDS YOU WILL HEAR<br></br>
WOULDNT BE POSSIBLE WITHOUT MY CLOSE<br></br>
COLLABORATORS<br></br></p> <span style={{fontSize: '15px', textAlign:'center', margin:'auto', width: "100%", display:"inline-block"}}>  OLIVER BLUE   <br></br>NKOSI KAMAL<br></br> TU! <br></br> WILI HENDRIXS</span>
<p><br></br>AND MORE AMAZING FRIENDS THAT ARE APART OF MY STORY THESE ARE CREATORS THAT<br></br>
IVE SPENT YEARS WITH BOUNCING IDEAS BACK AND FORTH<br></br>
TO MOLD AND PAINT THIS PICTURE FOR YOU THE LISTENER<br></br><br></br>
</p>
<div className="imgContainer">
    <img className="colorimage" src="/img/bodypix/3.png" style={{width:"100%", margin:"auto"}}/>
</div>
<p>
10 TRACKS PURE RAW EMOTION MY LIFE IN AUDIO FORMAT<br></br>
I LEFT IT ALL ON THE CANVAS WITH SO MUCH MORE TO COME<br></br><br></br>
</p>
<div className="imgContainer">
    <img className="colorimage" src="/img/bodypix/4.JPG" style={{width:"100%", margin:"auto"}}/>
</div>
<p>
IT DOESNT MATTER WHAT PART OF THE WORLD YOUR IN WHETHER<br></br>
YOUR SKINTONE IS THE SAME AS MINES OR NOT <br></br><br></br>
</p>
<div className="imgContainer">
    <img className="colorimage" src="/img/cvr.png" style={{width:"100%", margin:"auto"}}/>
</div>
<p className="bottom">
THIS MUSIC IS FOR THE PEOPLE AND WE HOPE YOU WILL ALLOW THIS ART TO SPREAD <br></br>
CONTIOUSLY UNTIL THEY ALL KNOW WHO THE ILLEST IS<br></br> <br></br>
</p>

<div className="imgContainer" >
    <img src="/img/sig.png" style={{width: "100%"}}></img>
</div>

<div className="linksContainer" > 
         <span style={{margin:"auto"}} >LISTEN TO INFLUENZA</span>
        <a className="musicLink" href="https://open.spotify.com/album/4lIHhQp7ls4qMefdeNdf0M"> spotify </a>
        <a className="musicLink" href="https://music.apple.com/us/album/influenza/1650316145?app=music"> apple music </a>
        <a className="musicLink" href="https://listen.tidal.com/album/254636101"> tidal </a>
        <a className="musicLink" href="https://music.amazon.com/albums/B0BJJ5JVDT"> amazon music </a>
        <a className="musicLink" href="https://www.pandora.com/artist/wara/influenza-explicit/ALtp62nw6btqfjX"> pandora </a>
        <a className="musicLink" href="https://www.deezer.com/album/367484837"> deezer </a>
        <a className="musicLink" href="https://open.qobuz.com/album/cvn3ejzhgqgpb"> qobuz </a>
        <a className="musicLink" href="https://music.apple.com/us/album/influenza/1650316145?app=itunes"> itunes (buy) </a>
        <a className="musicLink" href="https://amazon.com/dp/B0BJJ5JVDT"> amazon (buy) </a>
        <span style={{margin:"auto", marginTop:"15px"}} >WATCH THE VIDEOS</span>
        <a className="musicLink" href="https://www.youtube.com/channel/UCsdwuWUvNTzlc4wT_2oyIyA">youtube</a> 
</div>

<div className="imgContainer">
    <img className="colorimage" src="/img/bodypix/hvwiteinv.png" style={{width:"90%", margin:"auto"}}/>
</div>

</div>

{/* <div className="logoContainer" style={{display:"flex",mixBlendMode: "difference", justifyContent: "center", position:"absolute", top: "15vh",margin:"auto", width:"100vw", zIndex: "30"}} >
    <img src="/img/bodypix/influenza_logo_inv.jpg" style={{width: "90%",}}></img>
</div> */}
</div>
} 
