

import {Preload} from './components/preload';

import { Plx } from './components/plx';
import { Plx2 } from './components/plx2';

// import { AlbumCover } from './components/albumcover';

function App() {
  return (
    <div> 
    <Preload > </Preload>
    {/* <Plx ></Plx> */}
    <Plx2 > </Plx2>
    {/* <AlbumCover>
      
    </AlbumCover> */}

  </div>

  );
}

export default App;
