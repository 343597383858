export const Preload = () => {
const rows = [];

for (let i = 0; i < 66; i++) {
    let str = `/plx/${i}.gif`
    rows.push(<link rel="preload" href={str} as="image" key={i} />);
}
rows.push(<link rel="preload" href={`/img/bodypix/1.png`} as="image"/>)
rows.push(<link rel="preload" href={`/img/bodypix/2.png`} as="image"/>)
rows.push(<link rel="preload" href={`/img/bodypix/3.png`} as="image"/>)
rows.push(<link rel="preload" href={`/img/bodypix/4.JPG`} as="image"/>)
rows.push(<link rel="preload" href={`/img/bodypix/hvlogo.jpg`} as="image"/>)
rows.push(<link rel="preload" href={`/img/bodypix/hvwiteinv.png`} as="image"/>)
rows.push(<link rel="preload" href={`/img/bodypix/influenza_logo_inv.jpg`} as="image"/>)
rows.push(<link rel="preload" href={`/img/bodypix/influenza_logo.jpg`} as="image"/>)
rows.push(<link rel="preload" href={`/img/cvr.png`} as="image"/>)
rows.push(<link rel="preload" href={`/img/highwalk.JPG`} as="image"/>)
rows.push(<link rel="preload" href={`/img/hvlogo.png`} as="image"/>)
rows.push(<link rel="preload" href={`/img/hvwite.png`} as="image"/>)
rows.push(<link rel="preload" href={`/img/mtg.png`} as="image"/>)
rows.push(<link rel="preload" href={`/img/sep10-22.png`} as="image"/>)
rows.push(<link rel="preload" href={`/img/sig.png`} as="image"/>)
rows.push(<link rel="preload" href={`/img/stnd.png`} as="image"/>)








return <div className="preload"> {rows}</div>;
}